import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <Seo title="Plastic & Reconstructive Surgical Skills Course" />
        <section>
            <div style={{ backgroundImage: 'radial-gradient(circle at 100% 100%, #872424 0%, #9A2020 38%, #EF0E0E 82%)' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8}>

                            <div style={{ padding: '10rem 0 7rem', color: 'white' }} className="text-center">
                                <h1>
                                    Plastic & Reconstructive Surgical Skills Course
            </h1>
                                <p style={{fontSize: '1.4rem'}}>
                                    The Charles Wolfson Centre is proud to offer a novel practical skills course in plastic surgery, for no cost.
            </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
                <svg width="100%" height="100" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ marginTop: '-4rem' }}>
                    <path d="M0,120 L0,0 Q250,80 500,0 L500,120 Z" fill="white" />
                </svg>
            </div>
        </section>
        <section style={{background: 'white'}}>
            <Container fluid={'md'} style={{maxWidth: '1000px'}}>
                <Row>
                    <Col>
                        <div>
                            <p>An overview of the plastic surgery skills course offered by the Charles Wolfson Centre in collaboration with UCL, and the Royal Free Hospital.<br /><br />NB: Please note this course is currently only being offered to students of the Burns, Plastic and Reconstructive Surgery course at UCL, as well as medical students, however it will be more widely available at a later date. The online material is available to all upon membership, for no cost. </p>
                            <h2>Key Information</h2>
                            <ul>
                                <li>Course Dates: 13th and 14th of October</li>
                                <li>Audience: Junior Registrar, SHO, Medical Students</li>
                                <li>Registration: If you are interested in the course please email the Charles Wolfson Centre </li>
                            </ul>
                            <p>The aims of this course are to equip a junior registrar or SHO, with the basic principles and understanding of skills required for clinical reconstructive practice.  An exciting opportunity to receive feedback and tuition from senior registrars, and consultants.</p>
                            <p>The practical course, ran over 2 days, will consist of 6 practical stations;</p>
                            <ul>
                                <li>Equipment handling and principles of tissue handling</li>
                                <li>Suturing </li>
                                <li>Excision </li>
                                <li>Skin Grafts</li>
                                <li>Principles of flap design, in particular z-plasty </li>
                                <li>Principles of skin incisions and tendon repair </li>
                                <li>Principles of microsurgery </li>
                            </ul>
                            <p>Extensive online pre-course material regarding steps for each practical skill as well as details of the basic principles will also be included, and will be available online. A handbook will also be provided during the practical skills course.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    </Layout >

)

export default NotFoundPage
